<template>
  <component :is="!userData ? 'div' : 'b-card'">
    <template v-if="userData">
      <div>
        <template>
          <!-- BODY -->
          <validation-observer
            #default="{invalid}"
            ref="refFormObserver"
          >
            <!-- User Info: Input Fields -->
            <b-form
              id="formEditClient"
              class="p-2"
              @submit.prevent="editUserData(userData)"
            >
              <!-- Header: Personal Info -->
              <div class="d-flex">
                <feather-icon
                  icon="LayersIcon"
                  size="19"
                />
                <h4 class="mb-0 ml-50">
                  {{ t('Users.titleInformation') }}

                </h4>
              </div>
              <b-row class="mt-1 mb-2">
                <!-- first_name -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- first_name -->
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Users.userList.firstName')"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Users.userList.firstName')"
                      label-for="firstName"
                    >
                      <b-form-input
                        id="firstName"
                        :maxlength="50"
                        v-model="userData.first_name"
                        autofocus
                        :state="getValidationState(validationContext)"
                        trim
                        :placeholder="$t('Users.userList.firstNamePlaceholder')"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>
                <!-- last_name -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- last_name -->
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Users.userList.lastName')"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Users.userList.lastName')"
                      label-for="lastName"
                    >
                      <b-form-input
                        id="lastName"
                        :maxlength="50"
                        v-model="userData.last_name"
                        :state="getValidationState(validationContext)"
                        trim
                        :placeholder="$t('Users.userList.lastNamePlaceholder')"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- username -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- username -->
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Users.userList.username')"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Users.userList.username')"
                      label-for="username"
                    >
                      <b-form-input
                        id="username"
                        :maxlength="30"
                        autocomplete="off"
                        v-model="userData.username"
                        :state="getValidationState(validationContext)"
                        trim
                        :placeholder="$t('Users.userList.usernamePlaceholder')"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- password -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- password -->
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Users.userList.newPassword')"
                  >
                    <b-form-group
                      :label="$t('Users.userList.newPassword')"
                      label-for="password"
                    >
                      <b-form-input
                        id="password"
                        :maxlength="15"
                        autocomplete="off"
                        v-model="userData.password"
                        type="password"
                        :state="getValidationState(validationContext)"
                        trim
                        :placeholder="$t('Users.userList.passwordPlaceholder')"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- password confirm -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- password confirm -->
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Users.userList.passwordConfirm')"
                  >
                    <b-form-group
                      :label="$t('Users.userList.passwordConfirm')"
                      label-for="passwordConfirm"
                    >
                      <b-form-input
                        id="passwordConfirm"
                        :maxlength="15"
                        autocomplete="off"
                        v-model="userData.passwordConfirm"
                        type="password"
                        :state="getValidationState(validationContext)"
                        trim
                        :placeholder="$t('Users.userList.passwordConfirmPlaceholder')"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                      <div
                        v-if="!checkPassword"
                        class="alert-danger pl-1"
                      >
                        {{ $t('Users.error.password') }}
                      </div>
                    </b-form-group>
                  </validation-provider>

                </b-col>
                <!-- role_id -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- role_id -->
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Users.userList.roleName')"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Users.userList.roleName')"
                      label-for="roleName"
                    >
                      <v-select
                        v-model="userData.role_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="userRolOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="roleName"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- DNI -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- DNI -->
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Users.userList.dni')"
                    rules="required|integer|between:999999,999999999"
                  >
                    <b-form-group
                      :label="$t('Users.userList.dni')"
                      label-for="dni"
                    >
                      <b-form-input
                        id="dni"
                        :maxlength="9"
                        v-model="userData.dni"
                        :state="getValidationState(validationContext)"
                        trim
                        :placeholder="$t('Users.userList.dniPlaceholder')"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Email -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- Email -->
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Users.userList.email')"
                    rules= "email"
                  >
                    <b-form-group
                      :label="$t('Users.userList.email')"
                      label-for="email"
                    >
                      <b-form-input
                        id="email"
                        :maxlength="50"
                        v-model="userData.email"
                        :state="getValidationState(validationContext)"
                        trim
                        :placeholder="$t('Users.userList.emailPlaceholder')"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Phone -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- Phone -->
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Users.userList.phone')"
                    rules="integer|between:9999999,999999999999"
                  >
                    <b-form-group
                      :label="$t('Users.userList.phone')"
                      label-for="phone"
                    >
                      <b-form-input
                        id="phone"
                        :maxlength="12"
                        v-model="userData.phone"
                        :state="getValidationState(validationContext)"
                        trim
                        :placeholder="$t('Users.userList.phonePlaceholder')"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Status -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Users.userList.status')"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Users.userList.status')"
                      label-for="status"
                    >
                      <v-select
                        v-model="userData.active"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statusOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="status"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <hr>
              <!-- Buttons -->
              <b-row class="mt-2 mr-1">
                <b-col>
                  <b-button
                    variant="primary"
                    type="submit"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    :disabled="invalid"
                  >
                    {{ t('Clients.buttons.save') }}
                    <feather-icon
                      v-if="loader"
                      icon="SettingsIcon"
                      size="15"
                      class="spinner"
                    />
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="getData()"
                  >
                    {{ t('Clients.buttons.reset') }}
                  </b-button>
                </b-col>
                <b-link
                    :to="{ name: 'apps-users-list'}"
                    class="font-weight-bold d-block text-nowrap"
                >
                  <feather-icon
                      icon="ArrowLeftIcon"
                      size="15"
                  />
                  Volver
                </b-link>
              </b-row>
            </b-form>
          </validation-observer>
        </template>
      </div>
    </template>
  </component>
</template>

<script>
import {
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormRadioGroup, BRow, BCol, BLink,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { onUnmounted, ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import usersStoreModule from '@/views/Users/usersStoreModule'
import useUserList from '@/views/Users/users-list/useUserList'
import userRolStoreModule from '@/views/Users-roles/userRolStoreModule'
import useUsersRolesList from '@/views/Users-roles/users-roles-list/useUsersRolesList'
import { loader } from '@core/mixins/ui/globalMixin'

export default {
  components: {
    BLink,
    BCol,
    BRow,
    BCard,
    flatPickr,
    BForm,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [loader],

  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  computed: {
    checkPassword() {
      return (this.userData.password === this.userData.passwordConfirm)
    },
  },
  updated() {
    // this.resetForm()
  },
  mounted() {
    this.getData()
  },

  methods: {
    getData() {
      this.getUsersRoles()
      this.getUserData()
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      first_name: '',
      last_name: '',
      username: '',
      password: '',
      passwordConfirm: '',
      dni: '',
      email: '',
      status: '',
      role_id: '',
      phone: '',
    }

    const USERS_APP_STORE_MODULE_NAME = 'app-users'
    const USERS_ROLES_APP_STORE_MODULE_NAME = 'app-users-roles'

    // Register module
    if (!store.hasModule(USERS_APP_STORE_MODULE_NAME)) store.registerModule(USERS_APP_STORE_MODULE_NAME, usersStoreModule)
    if (!store.hasModule(USERS_ROLES_APP_STORE_MODULE_NAME)) store.registerModule(USERS_ROLES_APP_STORE_MODULE_NAME, userRolStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USERS_APP_STORE_MODULE_NAME)) store.unregisterModule(USERS_APP_STORE_MODULE_NAME)
      if (store.hasModule(USERS_ROLES_APP_STORE_MODULE_NAME)) store.unregisterModule(USERS_ROLES_APP_STORE_MODULE_NAME)
    })

    // const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetUserData = () => {
      this.userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const toast = useToast()
    const {
      t,
      getUserData,
      editUserData,
      statusOptions,
      userData,
    } = useUserList()

    const {
      getUsersRoles,
      userRolOptions,
    } = useUsersRolesList()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetUserData)

    return {
      userRolOptions,
      getUsersRoles,
      userData,
      statusOptions,
      editUserData,
      getUserData,
      toast,
      refFormObserver,
      getValidationState,
      resetUserData,
      resetForm,
      emit,
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
